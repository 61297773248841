require('./common.js');

require('../css/index.css');

require('../ext/swiper1031/swiper-bundle.css');
// const Swiper = require('../ext/swiper1031/swiper-bundle.js');

// console.log(Swiper);
const swiper = new Swiper('.swiper-lunbo', {
  lazy: true,
  autoplay:true,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
  }
});

const swiper1 = new Swiper('.swiper-value', {

  slidesPerView: 4,
  spaceBetween: 50,
  // effect: "coverflow",
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 15,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 50,
    },

  }


/*       
  // lazy: true,
  // autoplay:true,
  loop: true,
  slidesPerView: 2,
  spaceBetween: 50,
  centeredSlides: true,
  // effect: "coverflow",

  pagination: {
    el: '.swiper-pagination',
  },

  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 15,
    },
    1200: {
      slidesPerView: 2,
      spaceBetween: 50,
      coverflowEffect: {
        rotate: 0,
        stretch: -50,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      },
    },

  }
 */


});

var swiper2 = new Swiper('.swiper-news',{
  lazy: true,
  autoplay:true,
  // direction : 'vertical',
  followFinger : false,
  loop: true,
  speed:800,
  // mousewheel: true,
  pagination : {
    el:'.swiper-pagination',
  },
});





// let ScrollReveal = require('scrollreveal');
import ScrollReveal from 'scrollreveal';
window.scroll = ScrollReveal({
  mobile: false,
  reset: false,
  delay: 400,
  distance: '100px',
  duration: 600,
  origin: 'bottom', 
  scale: .6, 
  interval: 600,
  cleanup: true,
  // viewFactor: 0.0,
});

window.scroll.reveal('.animate-left', {origin:'left'});
window.scroll.reveal('.animate-right', {origin:'right'});
window.scroll.reveal('.animate-top', {origin:'top'});
window.scroll.reveal('.animate-bottom', {origin:'bottom'});
window.scroll.reveal('.animate-scale-2', {scale:2});
window.scroll.reveal('.animate-x', { rotate: {x:0, y:0, z:360}});

