require( __dirname + '/../css/tailwind_common.css');
require( __dirname + '/../font/font_1336983_1ya92pnrssw/iconfont.css');
require( __dirname + '/../css/style.css');


//----- common 1 ： lazy
const {Lazy} = require( __dirname + '/../js/lazy.js');
// console.log(Lazy);
let lazy = new Lazy;
lazy.exc( document.querySelectorAll("[data-_src]") );
lazy.exc();//所有具有data-_src的图片都执行lazy


//----- common 2 ： 统计
var _hmt = _hmt || [];
;(function() {
  // console.log(process.env.NODE_ENV);
  if(process.env.NODE_ENV!='production') return;
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?95615a48de99ccd4c90c12b2c8e29c1c";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();



// ----- common 3 ： header
window.select = {
  one(slt){
    return document.querySelector(slt);
  },
  all(slt){
    return document.querySelectorAll(slt);
  }
}


// 折叠导航
window.chgCollapse = (_this)=>{
  // console.log(_this.dataset.stat);
  _this.dataset.stat = _this.dataset.stat=='open' ? 'colse' : 'open';
  document.querySelector(".nav-menu").classList.toggle('left-0');
}

// 二级菜单显/隐
window.submenuShow = (_this, ctl='toggle') => {

  let dropdownMenu = _this.parentNode.nextElementSibling;

  if(dropdownMenu.dataset.stat!='show'){
    dropdownMenu.dataset.stat = 'show';
    _this.dataset.stat = 'show';
  }
  else{
    dropdownMenu.dataset.stat = 'hide';
    _this.dataset.stat = 'hide';
  }

}


// 搜索
window.searchToggle = ()=>{
  let cover = select.one(".search-box");

  if(!cover){
    cover = document.createElement("div");
    cover.className = "search-box";
    cover.innerHTML = searchTpl.innerHTML; 

    // console.log(cover);
    document.body.append(cover); 
  }
  else{
    // cover.classList.toggle("hidden");
    cover.style.display = cover.style.display!='none' ? "none" :'flex';
  }
}



