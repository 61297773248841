/* 	
var dom=document.getElementById("content"),clickt=0;
dom.addEventListener("click",function(e){
	clickt++;
	alert('你摸了我'+clickt+'下了。最多摸2下哦');
	
	if(clickt>=2){
		dom.removeEventListener(e.type,arguments.callee,false);
		console.log('解除点击事件');
	}
});
 */

 /*

//使用案例：20200919
let lz = new Lazy;
lz.exc( document.querySelectorAll("#imgbox1 img") );
lz.exc();//所有具有data-_src的图片都执行Lazy


*/



 
function Lazy(){
	// 用于Lazy的所有图片
	this.imgs=[];
	// scrollFun用于存储srcoll事件函数，否则不能解除scroll事件
	this.scrollFun=undefined;
}

// 执行方法
// 传入要Lazy的img数组
Lazy.prototype.exc=function(imgs)
{
	//1. 初始化 imgs
	if(Lazy.empty(imgs))
		imgs = document.querySelectorAll("img[data-_src]");	// 取回所有 lazyImgs
	
	// 注意：将nodeList 节点转为Array 注：否则后面在删除时nodelist会删除节点元素
	for(let img of imgs){
		if(img.nodeType==1 && img.dataset._src && img.dataset._src!=-1){
			this.imgs.push(img);
		}
	}


	//2. 如果没有要lazy的imgs则停止
	if(Lazy.empty(this.imgs)){
		console.log('No lazyImgs!');
		return;
	}

	// console.log('lazyImgs:', this.imgs);////测试点...
	
	//3. 初始加载一次（防止第一屏上有lazy的图片不能加载）
	this.loadImg();
	
	//4. 滚动lazy
	this.scrollFun=this.loadImg.bind(this);//存事件函数
	Lazy.bind(window, 'scroll', this.scrollFun);
	//Lazy.bind(document.body, 'scroll', this.loadImg);

}

// 递归计算元素到顶端的高度值
Lazy.toTopSize=function(elem){
	return elem.offsetParent ? elem.offsetTop + Lazy.toTopSize(elem.offsetParent) : elem.offsetTop;
}


/* 
 *  从loadImgs中计算并生成图片
 * 注意：此方法将做为window的onscroll的事件方法，故不可以用原型
 */
Lazy.prototype.loadImg=function()
{	
	//scrollTop各浏览器的取值不同，故做兼容处理
	let scrollTop= document.documentElement.scrollTop || document.body.scrollTop;

	//总高度(可见高度+上端卷起高度)
	let ttTop=scrollTop + document.documentElement.clientHeight;

	
	// var imgTop=0;//图片到顶高度
	// console.log('scroll 加载中...');//return;//测试点...
	//console.log(this.imgs);//return;
	
	// 加载图片
	let index=0;
	for(let img of this.imgs){

		if(img.dataset._src==-1) continue;

		//图片到顶高度(累积值)
		let imgTop = Lazy.toTopSize(img) || 0;
		// console.log(img,imgTop<=ttTop, index+' '+imgTop+","+ttTop);//测试点...

		// 进入可见区域
		if(imgTop<=ttTop){
			//取回src
			img.src=img.dataset._src;
			img.dataset._src='-1';
			// 从处理区中删除已加载过的图片(警告：不能在循环中删除图片)
			// this.imgs.splice(index, 1);
		}
		index++;
	}

	//图片加载完成，解除滚动绑定
	if(index==0) this.stop();
}




//干预结尾自动加载 20170826
Lazy.prototype.stop=function()
{
	Lazy.remove(window, 'scroll', this.scrollFun);
	this.imgs=[];
	// console.log("all Lazy Imgs Loaded!");
}


/*
 * 取回 Lazy 的所有图片对象
 * @imgs array 待处理的lazyImgs,不传则为document的所有img
 * lm: 加入data-_src!=-1 20170826
 */
Lazy.prototype._imgIni=function(imgs)
{
	// 如未传入图片，则为所有具有[data-_src]属性的图片
	if(Lazy.empty(imgs))
		imgs = document.querySelectorAll("img[data-_src]");
	
	// 将nodeList 节点转为Array 注：否则后面在删除时nodelist会删除节点元素
	// 取回所有 lazyImgs
	// 图片载入完成后 data-_src=-1
	for(let img of imgs){
		if(img.nodeType==1 && img.dataset._src && img.dataset._src!=-1){
			this.imgs.push(img);
		}
	}
}


/*
 * 当前元素的累加高度
 * 20170706
 */
Lazy._sumTop =function(element)
{
    var actualTop = element.offsetTop;
    var current = element.offsetParent;
		//console.log(current);
    
    while(current != null){
			actualTop += current.offsetTop;
			current = current.offsetParent;
    }
    
    return actualTop;
}

//判空： 数组或对象均可
Lazy.empty=function(imgArr){
	return !imgArr ? true : imgArr.length<1;
}


//绑定 20170704
Lazy.bind=function(element,type,handler){
    if(element.addEventListener)
        element.addEventListener(type,handler,false);
    else if(element.attachEvent)
        element.attachEvent('on'+type,handler);
    else
        element["on"+type]=handler;
};


//解除绑定 20170704
Lazy.remove=function(element,type,handler){
    if(element.removeEventListener)
        element.removeEventListener(type, handler, false);
    else if(element.deattachEvent) 
        element.deattachEvent('on' + type, handler);
    else 
        element["on" + type] = null;
}


export {Lazy};
